import React from "react";
import PropTypes from "prop-types";

import "./src/css/tailwind.css";
import "./src/css/animate.css";
import "./src/css/global.css";

import { ThemeProvider } from "./src/context/ThemeContext";
import sketchy from "theme-ui-sketchy-preset"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={sketchy}>{element}</ThemeProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
